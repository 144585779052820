.module-list {
    /* padding: 0 100px; */
}

.module-list h2 {
    font-size: 30px;
}

.module {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}
.module:last-child {
    margin-bottom: 60px;
}
.image {
    width: 33%;
} 
.content {
    width: calc(100% - 33%);
    padding: 30px;
}
.content h3 {
    margin: 0 0 16px 0;
    font-size: 24px;
}
.content h3 a {
    color: #161E2A;
}

.progress-bar {
    height: 10px;
    background-color: #eeeeee;
    border-radius: 10px;
    margin-bottom: 32px;
}
.progress-bar .progress {
    background-color: #272727;
    height: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.tick-mark-container {
    padding-bottom: 30px;
}
.tick-mark {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
}

.tick-mark::before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 5px;
    background-color: #E77651;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

.tick-mark::after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 100%;
    background-color: #E77651;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

@media (max-width: 1050px) {
    .module {
        flex-direction: column !important;
    }
    .image {
        width: 100%;
    }
    .image img {
        width: 100%;
    }
    .content {
        width: 100%;
    }
}
@media (max-width: 650px) {
    .content {
        padding: 30px 15px;
    }
}