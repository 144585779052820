.module-view {
    padding: 30px;
    margin-bottom: 200px;
}
.breadcrumb {

} 
.breadcrumb a {
    color: #6B7A90;
}
.breadcrumb span {
    color: #272727;
}
.media-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}
.media-frame {
    width: calc(100% - 33%);
    background-color: rgb(50, 54, 57);
    display: flex;
    flex-grow: 1;
}
.pdf-container {
    width: 100%;
}
.audio-container {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    aspect-ratio: 900 / 505;
}
.video-container {
    width: 100%;
    aspect-ratio: 900 / 505;
}
.video-container video {
    width: 100%;
    aspect-ratio: 900 / 505;
}
.media-list {
    width: 33%;
    background-color: rgb(50, 54, 57);
    color: white;
}

.media-list .header {
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
}
.media-list h2 {
    color: white;
    font-size: 14px;
    margin: 0;
    line-height: 24px;
}
.media-list .number-of-lessons {
    font-size: 14px;
    width: 140px;
    padding-left: 20px;
    text-align: right;
}
.media-list-items {
    overflow-y: scroll;
    margin: 0;
    padding: 0;
}
.media-list-items li {
    display: block;
    font-size: 14px;
}
.media-list-items li a {
    display: block;
    color: white;
    padding: 10px 12px 10px 0;
    display: flex;
    flex-direction: row;
}
.media-list-items li a .lesson-number {
    text-align: center;
    width: 30px;
}
.media-list-items li a .lesson-name {
    width: calc(100% - 30px - 50px);
}
.media-list-items li a .lesson-completed {
    width: 50px;
    padding-top: 5px;
}
.media-list-items li a .lesson-completed .check {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
}

.media-list-items li a .lesson-completed .check::before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 3px;
    background-color: #E77651;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

.media-list-items li a .lesson-completed .check::after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: #E77651;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

.media-list-items li a:hover {
    text-decoration: none;
}
.media-list-items li.active a {
    background-color: #E77651;
}
.media-list-items li.active a .lesson-completed .check::before,
.media-list-items li.active a .lesson-completed .check::after {
    background-color: white;
}

.mark-as-completed button {
    border: 0 none;
    background: none;
    background-color: #E77651;
    cursor: pointer;
    color: #f2e9e4;
    padding: 15px 30px;
}

@media (max-width: 1000px) {
    .media-container {
        flex-direction: column;
    }
    .media-frame {
        width: 100%;
    }
    .media-list {
        width: 100%;
    }
    .audio-container {
        height: 120px;
    }
}